<template>
  <div>
    <app-spinner v-if="!is_loaded" />
    <div v-else class="authorization-form">
      <div v-if="is_submit_success" class="authorization-form__error">
        <div class="authorization-form__error-img">
          <img src="@/assets/img/success-icon.svg" alt="Error">
        </div>
        <h1 class="authorization-form__error-title">The form was successfully completed!</h1>
        <p class="authorization-form__error-text">Thank you for completing the payment authorization form</p>
        <a href="https://altezza.travel/en/" class="btn btn--default">Return to the website</a>
      </div>
      <div v-if="!show_form || !is_hash_valid" class="authorization-form__error">
        <div class="authorization-form__error-img">
          <img src="@/assets/img/error-icon.svg" alt="Error">
        </div>
        <h1 class="authorization-form__error-title">{{ !is_hash_valid ? 'Sorry, something went wrong' : 'The form was already completed' }}</h1>
        <p class="authorization-form__error-text">{{ !is_hash_valid ? 'The payment authorization form was not found. Please ask your manager for assistance' : 'You have completed the form previously' }}</p>
        <a href="https://altezza.travel/en/" class="btn btn--default">Return to the website</a>
      </div>
      <template v-if="show_form && !is_submit_success">
        <app-cells>
          <h1 class="title">Credit / Debit card authorization form</h1>
        </app-cells>
        <div class="authorization-form__content">
          <div class="authorization-form__secure">
            <div class="authorization-form__secure-icon">
              <img src="@/assets/img/insurance-icon.svg" alt="insurance">
            </div>
            <p class="authorization-form__secure-text">
              We use HTTPS to encrypt all information you share with us. You can verify our security by checking our SSL
              certificate, which is provided through CloudFlare. Simply click on the padlock icon in your browser's address bar.
              We will securely store your personal and credit card information for six months after the conclusion of your
              journey with us. After this period, we will permanently delete your data and send you a confirmation email
              to verify this. Your safety and privacy are our utmost priorities.
            </p>
          </div>
          <div class="authorization-form__part">
            <div class="authorization-form__block">
              <app-form-group class="form-group--no-indent">
                <app-input
                  v-model="form.name"
                  placeholder="Name Surname"
                  :error="$v.form.name.$error"
                  name="namesurname"
                />
                <template #error>
                  <div v-if="$v.form.name.$dirty && !$v.form.name.required">Required field</div>
                </template>
              </app-form-group>
            </div>
            <p class="authorization-form__prompt">
              (please, write your name as it appears on your credit card / driving license)
            </p>
            <p class="authorization-form__text">
              By signing this credit / debit card authorization form I give my full consent to the following operations:
            </p>
            <p class="authorization-form__text">
              1. Altezza Travelling Limited may charge the payment (including the deposit payment) against my card for
              tourist services to be performed in the United Republic of Tanzania.
            </p>
            <p class="authorization-form__text">
              2. All charge-related expenses shall be debited from my card.
            </p>
          </div>
          <div class="authorization-form__part">
            <app-cells>
              <img src="@/assets/img/tour-detail-icon.svg" alt="Tour">
              <h2 class="title">Tour details</h2>
            </app-cells>
            <div class="authorization-form__values">
              <div class="authorization-form__value">
                <span>
                  <img src="@/assets/img/luggage-icon.svg" alt="Luggage">
                  Invoice:
                </span>
                <span>№
                  <a :href="`https://story.altezza.travel/invoice/shared/${invoice_data.hash}`" target="_blank" class="base-link">
                  {{ invoice_data.number }}
                </a>
                </span>
              </div>
            </div>
            <div class="authorization-form__values">
              <div class="authorization-form__value">
              <span>
                <img src="@/assets/img/amount-icon.svg" alt="Amount">
                Amount to be debited:
              </span>
                <span>
                  {{ invoice_data.amount_payable.toLocaleString() }} USD
                  + 3,5% commission = {{ invoice_data.amount_payable_with_commission.toLocaleString() }} USD</span>
              </div>
            </div>
          </div>
          <div class="authorization-form__part">
            <app-cells>
              <img src="@/assets/img/payment-method-icon.svg" alt="Payment">
              <h2 class="title">Payment method</h2>
            </app-cells>
            <div class="authorization-form__cardholder">
              <app-form-group label="Card number" label-for="card-number">
                <app-input
                  @change.native="$v.form.card_number.$touch"
                  v-model="form.card_number"
                  v-cardformat:formatCardNumber
                  :error="$v.form.card_number.$error"
                  placeholder="0000 0000 0000 0000"
                  id="card-number"
                  name="card-number"
                  class="authorization-form__card-input"
                />
                <div class="authorization-form__card-img"></div>
                <template #error>
                  <div v-if="$v.form.card_number.$dirty && !$v.form.card_number.minLength">Invalid card number</div>
                  <div v-if="$v.form.card_number.$dirty && !$v.form.card_number.required">Required field</div>
                </template>
              </app-form-group>
              <app-form-group label="Name of the card holder" label-for="card-holder">
                <app-input
                  v-model="form.card_holder_name"
                  :error="$v.form.card_holder_name.$error"
                  placeholder="Name Surname"
                  id="card-holder"
                  name="card-holder"
                />
                <template #error>
                  <div v-if="$v.form.card_holder_name.$dirty && !$v.form.card_holder_name.required">Required field</div>
                </template>
              </app-form-group>
              <div class="authorization-form__cardholder-line">
                <app-form-group label="Valid until" class="form-group--no-indent" label-for="valid">
                  <app-input
                    @change.native="$v.form.valid_until.$touch"
                    v-model="form.valid_until"
                    v-cardformat:formatCardExpiry
                    :error="$v.form.valid_until.$error"
                    placeholder="MM / YYYY"
                    id="valid"
                    name="valid"
                  />
                  <template #error>
                    <div v-if="$v.form.valid_until.$dirty && !$v.form.valid_until.required">Required field</div>
                    <div v-if="$v.form.valid_until.$dirty && !$v.form.valid_until.validUntilRegex">Invalid date</div>
                  </template>
                </app-form-group>
                <app-form-group label="CVV" class="form-group--no-indent" label-for="cvv">
                  <app-input
                    v-model="form.cvv"
                    v-mask="{mask: '9999', placeholder: ''}"
                    :error="$v.form.cvv.$error"
                    placeholder="123"
                    id="cvv"
                    name="cvv"
                  />
                  <template #error>
                    <div v-if="$v.form.cvv.$dirty && !$v.form.cvv.required">Required field</div>
                    <div v-if="$v.form.cvv.$dirty && !$v.form.cvv.minLength">At least 3 character</div>
                  </template>
                </app-form-group>
                <img class="authorization-form__cardholder-credit" src="@/assets/img/credit-card.svg" alt="Credit">
              </div>
            </div>
            <div class="authorization-form__block authorization-form__block--grid">
              <app-form-group label="Phone number of the card holder">
                <app-phone
                  v-model="form.phone"
                  placeholder="+1 555 555-1234"
                  :error="$v.form.phone.$dirty && (!$v.form.phone.required || (form.phone === 0))"
                  name="phone_m"
                  autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.form.phone.$dirty && !$v.form.phone.required">Required field</div>
                  <div v-if="$v.form.phone.$dirty && (form.phone === 0) && $v.form.phone.required">Invalid phone number format</div>
                </template>
              </app-form-group>
              <app-form-group label="E-mail of the card holder" label-for="email_m">
                <app-input
                  v-model="form.email"
                  id="email_m"
                  type="email"
                  placeholder="mail@example.com"
                  :error="$v.form.email.$error"
                  name="email_m"
                  autocomplete="off"
                />
                <template #error>
                  <div v-if="$v.form.email.$dirty && !$v.form.email.required">Required field</div>
                  <div v-if="$v.form.email.$dirty && !$v.form.email.email">Invalid e-mail format</div>
                </template>
              </app-form-group>
              <app-form-group label="Passport / Driver’s license">
                <app-uploader v-model="form.id_copy" :extensions="['application/pdf', 'image/jpeg', 'image/png', 'image/tiff', 'image/bmp']" />
                <template #error>
                  <div v-if="$v.form.id_copy.$dirty && !$v.form.id_copy.required">Required field</div>
                </template>
              </app-form-group>
            </div>
          </div>
          <div class="authorization-form__part">
            <app-cells>
              <img src="@/assets/img/billing-address-icon.svg" alt="Address">
              <h2 class="title">Billing address of the card holder</h2>
            </app-cells>
            <div class="authorization-form__block authorization-form__block--grid">
              <app-form-group label="Country">
                <v-select
                  v-model="form.country"
                  :reduce="item => item.code"
                  :options="countries_options"
                  :filterable="true"
                  :clearable="false"
                  :searchable="true"
                  label="name"
                  placeholder="Country"
                  class="select"
                  :class="{'select--error': $v.form.country.$error}"
                >
                  <template #open-indicator>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                      <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                    </svg>
                  </template>
                  <template #open-indicator>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                      <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
                    </svg>
                  </template>
                  <template #option="option">
                    <div class="select__item d-center">{{ option.name }}</div>
                  </template>
                  <template #selected-option="option">
                    <div class="selected d-center">{{ option.name }}</div>
                  </template>
                </v-select>
                <template #error>
                  <div v-if="$v.form.country.$dirty && !$v.form.country.required">Required field</div>
                </template>
              </app-form-group>
              <app-form-group label="State" label-for="region">
                <app-input
                  v-model="form.region"
                  id="region"
                  placeholder="State"
                  :error="$v.form.region.$error"
                  name="region"
                />
                <template #error>
                  <div v-if="$v.form.region.$dirty && !$v.form.region.required">Required field</div>
                </template>
              </app-form-group>
              <app-form-group label="City" label-for="city">
                <app-input
                  v-model="form.city"
                  id="city"
                  placeholder="City"
                  :error="$v.form.city.$error"
                  name="city"
                />
                <template #error>
                  <div v-if="$v.form.city.$dirty && !$v.form.city.required">Required field</div>
                </template>
              </app-form-group>
              <app-form-group label="Street" label-for="street">
                <app-input
                  v-model="form.street"
                  id="street"
                  placeholder="Street"
                  :error="$v.form.street.$error"
                  name="street"
                />
                <template #error>
                  <div v-if="$v.form.street.$dirty && !$v.form.street.required">Required field</div>
                </template>
              </app-form-group>
              <app-form-group label="Postal code" label-for="postal_code">
                <app-input
                  v-model="form.postal_code"
                  id="postal_code"
                  placeholder="Postal code"
                  :error="$v.form.postal_code.$error"
                  name="postal_code"
                />
                <template #error>
                  <div v-if="$v.form.postal_code.$dirty && !$v.form.postal_code.required">Required field</div>
                </template>
              </app-form-group>
            </div>
          </div>
          <div class="authorization-form__part">
            <div class="authorization-form__block">
              <app-form-group label="Signature of card holder">
                <vue-signature-pad
                  v-model="form.signature"
                  height="120px"
                  ref="signaturePad"
                  class="authorization-form__signature"
                  :class="{'authorization-form__signature--error': $v.form.signature.$error}"
                  :options="{ onEnd }"
                />
              </app-form-group>
              <app-cells :indent="false" position="center">
                <app-button @click.native="onPadUndo" theme="warn">Undo</app-button>
              </app-cells>
            </div>
          </div>
          <div class="authorization-form__part">
            <app-form-group class="form-group--no-indent">
              <label class="authorization-form__label">
                <app-checkbox v-model="confirmation" />
                <span>I’ve read and agree with <a href="https://altezza.travel/terms-and-conditions" target="_blank">Terms and Conditions</a></span>
              </label>
              <template #error>
                <div v-if="$v.confirmation.$dirty && !$v.confirmation.sameAs">Required field</div>
              </template>
            </app-form-group>
          </div>
          <app-button @click.native="onCheckForm" ref="submit" :disabled="$v.$error">Submit</app-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getPublicClientInvoice, putPublicClientInvoice, getCountries } from '@/http'
import { required, email, minLength, helpers, sameAs } from 'vuelidate/lib/validators'
import { VueSignaturePad } from 'vue-signature-pad'
import { format, parse } from 'date-fns'
import enLocale from 'date-fns/locale/en'
const validUntilRegex = helpers.regex('validUntilRegex', /^(0[1-9]|1[0-2]) \/ ?(20[2-9][0-9])$/)
export default {
  name: 'AuthorizationForm',
  components: { VueSignaturePad },
  data() {
    return {
      is_loaded: false,
      is_hash_valid: true,
      show_form: true,
      is_submit_success: false,
      form: {
        signature: '',
      },
      invoice_data: {},
      countries_options: [],
      confirmation: false,
    }
  },
  validations: {
    form: {
      name: { required },
      card_holder_name: { required },
      card_number: { required, minLength: minLength(13) },
      valid_until: { required, validUntilRegex },
      cvv: { required, minLength: minLength(3) },
      phone: { required },
      email: { required, email },
      country: { required },
      region: { required },
      city: { required },
      street: { required },
      postal_code: { required },
      signature: { required },
      id_copy: { required },
    },
    confirmation: { sameAs: sameAs(() => true) }
  },
  created() {
    getPublicClientInvoice(this.$route.params.invoice_hash)
      .then(response => {
        getCountries().then(res => { this.countries_options = res.data })
        this.show_form = !response.data.completed
        this.invoice_data = {
          hash: response.data.hash,
          number: response.data.number,
          amount_payable: response.data.amount_payable,
          amount_payable_with_commission: response.data.amount_payable_with_commission,
        }
        this.is_loaded = true
      })
      .catch(() => {
        this.show_form = false
        this.is_hash_valid = false
        this.is_loaded = true
      })
  },
  methods: {
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.form.signature = data
    },
    onPadUndo() {
      this.$refs.signaturePad.undoSignature()
      if (this.$refs.signaturePad.isEmpty()) this.form.signature = ''
    },
    onCheckForm() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          type: 'info',
          title: 'Check that the form fields are filled in correctly',
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      const form_data = {
        ...this.form,
        valid_until: this.form.valid_until.replace(/\s+/g, ''),
        id_copy: this.form.id_copy.id
      }
      putPublicClientInvoice(this.$route.params.invoice_hash, { auth_form_data: form_data })
        .then(() => {
          this.$refs.submit.preload = false
          this.is_submit_success = true
        })
        .catch(() => {
          this.$refs.submit.preload = false
        })
    },
    formatDate(date) {
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'DD MMMM YYYY', {locale: enLocale})
    },
  }
}
</script>

<style scoped lang="sass">
.authorization-form__title
  margin-bottom: 30px

.authorization-form__content
  max-width: 675px

.authorization-form__part
  margin-bottom: 50px

  +max-w($mobile_sm)
    margin-bottom: 30px

.authorization-form__block
  max-width: 440px

.authorization-form__block--grid
  display: grid
  grid-template-columns: 210px 210px
  gap: 0 20px
  align-items: flex-end

  +max-w($mobile_sm)
    grid-template-columns: 1fr

.authorization-form__text
  margin-bottom: 20px
  color: $color-text-base
  font-size: 13px
  line-height: 16px
  font-weight: 500

.authorization-form__prompt
  margin: 10px 0 20px
  color: #181818
  font-size: 13px
  font-weight: 400

.authorization-form__values
  margin-bottom: 20px

.authorization-form__values--flex
  flex-wrap: wrap
  display: flex
  gap: 20px

.authorization-form__value
  flex-shrink: 0
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 10px

  span
    font-size: 13px
    line-height: 16px
    font-weight: 500

    &:first-child
      display: flex
      align-items: center
      gap: 10px
      color: #5e5e5e

    &:last-child
      color: #181818

.authorization-form__cardholder
  max-width: 400px
  margin-bottom: 50px
  padding: 30px
  border: 1px solid #c1c1c1
  border-radius: 10px
  background-color: #f9f9f9

  +max-w($mobile_sm)
    margin-bottom: 30px

.authorization-form__cardholder-line
  display: grid
  grid-template-columns: 100px 100px 1fr
  gap: 10px
  align-items: flex-end

.authorization-form__cardholder-credit
  justify-self: flex-end

.authorization-form__card-img
  position: absolute
  top: 50%
  right: 10px
  width: 32px
  height: 22px
  transform: translateY(-50%)
  background-position: center center
  background-repeat: no-repeat
  opacity: 0
  transition: opacity 0.3s

.authorization-form__card-input.visa + .authorization-form__card-img
  background-image: url("~@/assets/img/visa.svg")
  opacity: 1

.authorization-form__card-input.mastercard + .authorization-form__card-img
  background-image: url("~@/assets/img/mastercard.svg")
  opacity: 1

.authorization-form__card-input.amex + .authorization-form__card-img
  background-image: url("~@/assets/img/amex.svg")
  opacity: 1

.authorization-form__card-input.maestro + .authorization-form__card-img
  background-image: url("~@/assets/img/maestro.svg")
  opacity: 1

.authorization-form__signature
  border: 1px solid #acacac
  transition: border-color 0.3s

.authorization-form__signature--error
  border-color: $color-error

.authorization-form__label
  display: flex
  align-items: flex-start
  gap: 5px
  max-width: 325px
  font-size: 13px
  cursor: pointer

  span
    padding-top: 1px

  a
    color: $color-theme
    transition: color 0.3s

    &:hover
      color: #72a915

.authorization-form__error
  min-height: calc(100vh - 127px)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center

.authorization-form__error-img
  margin-bottom: 10px
  line-height: 0

.authorization-form__error-title
  margin-bottom: 10px
  font-size: 18px
  font-weight: 600

.authorization-form__error-text
  margin-bottom: 20px
  font-size: 13px

.authorization-form__secure
  display: flex
  align-items: center
  gap: 12px
  margin-bottom: 30px
  padding: 10px 15px
  border: 1px solid #87b736
  border-radius: 8px
  background-color: #fcfdfb

.authorization-form__secure-icon
  flex-shrink: 0

.authorization-form__secure-text
  color: #60753c
  font-size: 11px
  line-height: 15px
</style>
